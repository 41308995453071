<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
/**
 * Dashboard Component
 */
export default {
    page: {
        title: "Config Work Permit",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            title: "Config Work Permit",
            items: [
                {
                    text: "Master",
                    href: "/",
                },
                {
                    text: "Config Work Permit",
                    active: true,
                },
            ],

            // variabel untuk option v-select
            jenisPerizinan: [],
            jenisPerizinan_selected: [],
            perizinan_selected: "",

            // variable Page Table
            loadingTable: true,
            dataTable: [],
            pagingTable: [],
            showDataToTable: "",
            showDataFromTable: "",
            totalDataTable: "",
            currentTablePage: "",
            searchDataTable: "",
        };
    },
    mounted() {
        // Load data awal banget
        let self = this;

        // load data jenis perizinan
        var config_ref_perizinan = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/all-perizinan",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config_ref_perizinan)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.jenisPerizinan = response_data_fix.referensi;
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data[0];
                if (response_data.meta.code == 200) {
                    self.loadingTable = false;
                    self.dataTable = response_data_fix.data;
                    self.pagingTable = response_data_fix.links;
                    self.showDataToTable = response_data_fix.to;
                    self.showDataFromTable = response_data_fix.from;
                    self.totalDataTable = response_data_fix.total;
                    self.currentTablePage = response_data_fix.current_page;
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    methods: {
        // Method dari masing masing fungsi di dalam file ini
        fullDateTimeFormat(datetime) {
            return moment(datetime).format("YYYY-MM-DD HH:mm");
        },
        toPage(url) {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: url,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        searchTable() {
            let self = this;
            self.loadingTable = true;
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
                params: {
                    search: self.searchDataTable,
                    mps_mpj_id: self.jenisPerizinan_selected?.mpj_id,
                    mps_mp_id: self.perizinan_selected?.mp_id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        self.loadingTable = false;
                        self.dataTable = response_data_fix.data;
                        self.pagingTable = response_data_fix.links;
                        self.showDataToTable = response_data_fix.to;
                        self.showDataFromTable = response_data_fix.from;
                        self.totalDataTable = response_data_fix.total;
                        self.currentTablePage = response_data_fix.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        changeStatusData(status, id) {
            let self = this;

            let text_alert = "";
            if (status == "ENABLE") {
                text_alert = "Apakah anda yakin ingin mengaktifkan data tersebut?";
            } else {
                text_alert = "Apakah anda yakin ingin menonaktifkan data tersebut?";
            }

            Swal.fire({
                title: "Perhatian",
                text: text_alert,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Iya`,
                denyButtonText: `Tidak`,
            }).then((result) => {
                if (result.isConfirmed) {
                    self.loadingTable = true;
                    Swal.fire({
                        title: '<i class="fa fa-refresh fa-spin"></i>',
                        text: "Loading...",
                        showConfirmButton: false,
                    });

                    var config = {
                        method: "delete",
                        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/perizinan-sub",
                        data: {
                            mps_id: id,
                            status: status,
                        },
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    };
                    axios(config).then((response) => {
                        var response_data = response.data;
                        if (response_data.meta.code != "200") {
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: response_data.data.message,
                            });
                        } else {
                            let timerInterval;
                            Swal.fire({
                                icon: "success",
                                title: "Success",
                                text: "Success update data.",
                                timer: 2000,
                                timerProgressBar: true,
                                showCancelButton: false,
                                showConfirmButton: false,
                                didOpen: () => {
                                    timerInterval = setInterval(() => {
                                        const content = Swal.getContent();
                                        if (content) {
                                            const b = content.querySelector("b");
                                            if (b) {
                                                b.textContent = Swal.getTimerLeft();
                                            }
                                        }
                                    }, 100);
                                },
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            }).then((result) => {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.searchTable();
                                }
                            });
                        }
                    });
                } else {
                    Swal.close();
                }
            });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Config Work Permit Type</div>
                            </div>
                            <div class="col-md-6">
                                <div class="text-end">
                                    <!-- <router-link :to="{ name: 'add-sub-perizinan' }" class="btn btn-sm btn-success"><i class="fa fa-plus"></i> Add Work Permit Type</router-link> -->
                                </div>
                            </div>
                            <!-- <div class="col-md-12">
                                <br />
                                <div class="row">
                                    <div class="col-md-4">
                                        <v-select id="formrow-pilihan-eselon-select" :options="jenisPerizinan" label="mpj_nama" v-model="jenisPerizinan_selected" @option:selected="searchTable()" placeholder="Pilih Jenis Perizinan"></v-select>
                                    </div>
                                    <div class="col-md-5">
                                        <v-select id="formrow-pilihan-eselon-select" :options="jenisPerizinan_selected.perizinan" label="mp_nama" v-model="perizinan_selected" @option:selected="searchTable()" :disabled="!jenisPerizinan_selected.perizinan" placeholder="Pilih Perizinan"></v-select>
                                    </div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control" id="searchTable" v-on:change="searchTable" v-model="searchDataTable" placeholder="Cari Data ..." />
                                    </div>
                                </div>
                                <br />
                            </div> -->
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px">No</th>
                                                <th>Name Work Permit Type</th>
                                                <th style="width: 225px">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="8"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-for="(row_data, key_data) in dataTable" :key="key_data" v-else>
                                                <td class="text-center">
                                                    <div v-if="key_data + 1 == 10">{{ currentTablePage }}0.</div>
                                                    <div v-else>{{ currentTablePage - 1 != 0 ? currentTablePage - 1 : "" }}{{ key_data + 1 }}.</div>
                                                </td>
                                                <td>
                                                    {{ row_data.mps_nama }}
                                                </td>
                                                <td class="text-center">
                                                    <!-- <router-link :to="{ name: 'konfigurasi-form', params: { mps_id: row_data.mps_id } }" class="btn btn-primary btn-sm mb-1 w-100"
                                                        ><i class="fa fa-edit"></i> Configuration Form <span style="font-size: 15px" v-if="row_data.is_konfig_form == 't'"> - <i class="bx bx-check"></i></span
                                                    ></router-link> -->
                                                    <router-link :to="{ name: 'konfigurasi-matrix-approval', params: { mps_id: row_data.mps_id } }" class="btn btn-primary btn-sm mb-1 w-100"
                                                        ><i class="fa fa-edit"></i> Matrix Approval <span style="font-size: 15px" v-if="row_data.is_konfig_matrix == 't'"> - <i class="bx bx-check"></i></span
                                                    ></router-link>
                                                    <!-- <router-link :to="{ name: 'konfigurasi-disclaimer', params: { mps_id: row_data.mps_id } }" class="btn btn-primary btn-sm mb-1 w-100"
                                                        ><i class="fa fa-edit"></i> Configuration Disclaimer <span style="font-size: 15px"> - <i class="bx bx-check"></i></span
                                                    ></router-link> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}</div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li v-for="(page, key_page) in pagingTable" v-bind:class="{ active: page.active }" :key="key_page" class="page-item">
                                                <div v-on:click="toPage(page.url)" class="page-link c-page-link-number">
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
